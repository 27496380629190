<template>
  <el-table border :data="sizeLevelCategoryPriceList">
    <el-table-column label="尺码" prop="sizeName"></el-table-column>
    <el-table-column label="最低售价" prop="lowerLimitPrice"></el-table-column>
    <el-table-column
      v-for="item in priceList"
      :key="item.prop"
      :label="`${item.label}价格`"
      :prop="`${item.prop}`"
    ></el-table-column>
  </el-table>
</template>

<script>
import { PRICE_TYPE_DIC, PRICE_TYPE_FIXED } from '@/utils/constant'
import { getSizeLevelCategoryPriceList } from '@/views/product/utils'

export default {
  props: {
    sizeList: {
      default: () => ([])
    }
  },
  data() {
    return {
      PRICE_TYPE_DIC,
      PRICE_TYPE_FIXED,
      type: '',
      category: ''
    }
  },
  computed: {
    sizeLevelCategoryPriceList({ sizeList }) {
      return this.$attrs.sizeLevelCategoryPriceList || getSizeLevelCategoryPriceList(sizeList) || []
    },
    priceList({ sizeLevelCategoryPriceList }) {
      return sizeLevelCategoryPriceList[0]?.priceList || []
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.el-radio {
  width: 100%;
  margin-bottom: 28px;
}
</style>
