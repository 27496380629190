<template>
  <div>
    <el-radio
      v-for="item in PRICE_TYPE_DIC"
      :key="item.value"
      v-model="type"
      :label="item.value"
    >
      {{ item.label }}
      <template v-if="item.value === PRICE_TYPE_FIXED">
        <avueCrudSelect
          style="width: 188px;margin-left: 16px;"
          size="small"
          v-model="category"
          :dic="categoryList"
          @change="type = PRICE_TYPE_FIXED"
        ></avueCrudSelect>
      </template>
    </el-radio>
    <sizePriceTable class="mt20" :sizeList="value.sizeList"></sizePriceTable>
  </div>
</template>

<script>
import { PRICE_TYPE_DIC, PRICE_TYPE_FIXED, PRICE_TYPE_SYS } from '@/utils/constant'
import { getLevelCategoryList } from '@/views/product/utils'
import sizePriceTable from '@/views/user/resellerPage/module/setPrice/module/setPrice/sizePriceTable'
import { fixedLevelCreate, fixedLevelDelete, fixedLevelList, fixedLevelUpdate } from '@/api/accountApi'

export default {
  components: {
    sizePriceTable
  },
  props: {
    value: {
      default: () => ({})
    },
    mainAccountUid: Number | String
  },
  data() {
    return {
      PRICE_TYPE_DIC,
      PRICE_TYPE_FIXED,
      type: '',
      category: '',
      fixedLevelItem: {}
    }
  },
  computed: {
    categoryList({ value }) {
      return getLevelCategoryList(value.sizeList)
    }
  },
  created() {
    if (this.value.fixedLevelCategoryConfigId) {
      this.init()
    } else {
      this.type = PRICE_TYPE_SYS
    }
  },
  methods: {
    async onsubmit() {
      if (!this.type) {
        this.$message.warning('请先选择价格分类')
        return
      }
      if (this.type === PRICE_TYPE_FIXED && !this.category) {
        this.$message.warning('请选择具体档位')
        return
      }

      let res = await this.doFn()
      if (!res) return

      this.$message.success('操作成功')
      this.$emit('success')
    },
    doFn() {
      const hadFixed = this.fixedLevelItem.id
      const isFixed = this.type === PRICE_TYPE_FIXED
      if (isFixed) { // 设置为固定档位价格
        // 若已经是固定档位价格则修改，否则为添加
        return hadFixed ? this.doEdit() : this.doAdd()
      } else { // 设置为系统阶梯价格
        // 若已经是系统阶梯价格则不变，否则删除固定档位
        return hadFixed ? this.doDel() : true
      }
    },
    doAdd() {
      return awaitResolveLoading(
        fixedLevelCreate({
          levelCategoryItemId: this.category,
          mainAccountUid: this.mainAccountUid,
          prototypeId: this.value.id
        })
      )
    },
    doEdit() {
      return awaitResolveLoading(
        fixedLevelUpdate({
          id: this.fixedLevelItem.id,
          levelCategoryItemId: this.category,
          mainAccountUid: this.mainAccountUid,
          prototypeId: this.value.id
        })
      )
    },
    doDel() {
      return awaitResolveLoading(
        fixedLevelDelete({
          idList: [this.fixedLevelItem.id]
        })
      )
    },

    async init() {
      let res = await awaitResolveLoading(
        fixedLevelList({
          prototypeId: this.value.id,
          mainAccountUid: this.mainAccountUid
        })
      )
      if (!res) return this.$emit('cancel')

      let fixedLevelItem = this.fixedLevelItem = res.detail?.[0] || {}

      if (fixedLevelItem.id) {
        this.category = fixedLevelItem.levelCategoryItemId
        this.type = PRICE_TYPE_FIXED
      } else {
        this.type = PRICE_TYPE_SYS
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-radio {
  width: 100%;
  margin-bottom: 24px;
}
</style>
